import React from 'react';
import { formatNumber } from 'devextreme/localization';

function gridCellData(gridData) {
  // console.log(gridData.text);
  return gridData.text;
}

export default function ColorCell(cellData) {  
  if (cellData.column.name == 'STATUS') {    
    return (
      <div className={cellData.text == 'Valido' ? 'green' : (cellData.text == 'Scaduto'? 'red' : cellData.text == "In scadenza" ? 'yellow' : "")}>
      {/* <div className={cellData.text == 'Valido' ? 'green' : cellData.text == 'Mancante' ? 'red' : cellData.text == 'Scaduto' ? 'yellow' : ""}> */}
            <div className="diff">{gridCellData(cellData)}</div>
        {/* <div className="diff">{Math.abs(gridCellData(cellData).diff).toFixed(2)}</div> */}
      </div>
    );
  }
}

