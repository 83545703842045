import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import Accordion, { Item } from 'devextreme-react/accordion';
import CheckBox from 'devextreme-react/check-box';
import TagBox from 'devextreme-react/tag-box';
import Slider, { Tooltip, Label } from 'devextreme-react/slider';
import axios from 'axios';
import appInfo from '../../app-info';


// import service from './data.js';
// import CustomTitle from './CustomTitle.js';

const companyLabel = { 'aria-label': 'Company' };

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: 0,
            multiple: false,
            collapsible: false,
            animationDuration: 300,
            ruoli: []
        };
        this.selectionChanged = this.selectionChanged.bind(this);
        this.selectedItemsChanged = this.selectedItemsChanged.bind(this)
    }

    async UNSAFE_componentWillMount() {
        const url = appInfo.urlWebApi;
        const user = this.props.user;

        var result = [];

        console.log('DataSourceRiepilogo2');
        const queryParameters = new URLSearchParams(window.location.search);
        const prev = queryParameters.get("d");
        console.log(queryParameters);
        console.log(prev);
        var result;
        console.log(user);
        if (user) {

            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });
            await instance.get('/api/FlussiApprovativi/GetDatiRiepilogoContratto', {
                params: { internalKey: user.internalKey, idPreventivo: prev }
            })
                .then(function (response) {
                    console.log('response')
                    console.log(response.data);
                    result = response.data;
                })
                .catch(function () {
                })
                .finally(function () { });

            this.setState({ ruoli: result });
        }
    }




    render() {
        const {
            selectedItems, multiple, collapsible, animationDuration, ruoli
        } = this.state;

        const renderCustomItem = () => {
            return <div>Custom Item</div>;
        }


        function myCast_ToString(str) {
            if (str) {
                return str;
            } else {
                return '';
            }

        }

        function valueByTypeCalcolato(item) {
            var tipo = item.TIPO_CAMPO;
            var valore = myCast_ToString(item.VALORE_CALCOLATO);

            if (tipo == 'VALUTA' || tipo == 'NUMERICO')
                valore = parseFloat(valore).toLocaleString("it-IT", { minimumFractionDigits: 2 });
            else if (tipo == 'YN')
                valore = valore == '0' ? 'No' : 'Sì';

            return valore;
        }

        function valueByTypeModificato(item) {
            var tipo = item.TIPO_CAMPO;
            var valore = myCast_ToString(item.VALORE_MODIFICATO);

            if (tipo == 'VALUTA' || tipo == 'NUMERICO')
                valore = parseFloat(valore).toLocaleString("it-IT", { minimumFractionDigits: 2 });
            else if (tipo == 'YN')
                valore = valore == '0' ? 'No' : 'Sì';


            console.log('ciao');

            console.log(valore);
            return valore;
        }


        function myItem(group) {

            if (group == "TESTATA") {
                return ruoli.filter(x => x.GRUPPO == group).map(function (item) {
                    return (
                        <div>
                            <b>{item.DESCRIZIONE}</b><br />
                            <span className='spanStyle'>{item.VALORE_CALCOLATO == item.VALORE_MODIFICATO ? valueByTypeCalcolato(item) + ' ' + myCast_ToString(item.UDM) ?? '' : (valueByTypeModificato(item) + ' ' + myCast_ToString(item.UDM) ?? '' + '(Orig. ' + valueByTypeCalcolato(item) + ')')}</span><br />
                        </div>
                    )
                });
            }
            else {
                return ruoli.filter(x => x.GRUPPO != "TESTATA").map(function (item) {
                    return (
                        <div>
                            <b>{item.DESCRIZIONE}</b><br />
                            <span className='spanStyle'>{item.VALORE_CALCOLATO == item.VALORE_MODIFICATO ? valueByTypeCalcolato(item) + ' ' + myCast_ToString(item.UDM) ?? '' : (valueByTypeModificato(item) + ' ' + myCast_ToString(item.UDM) ?? '' + '(Orig. ' + valueByTypeCalcolato(item) + ')')}</span><br />
                        </div>
                    )
                });
            }


        }

        function myGroup() {
            var result = [];
            ruoli.forEach(function (a) {
                if (result.indexOf(a.GRUPPO) == -1) result.push(a.GRUPPO);
            });

            return (result.map(group => (

                <Item title={group}>
                    {
                        myItem(group)
                    }
                </Item>
            )));
        }

        function onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }

        return (
            <div id="accordion">
                <Accordion
                    collapsible={this.props.collapsible}
                    multiple={false}
                    animationDuration={animationDuration}        
                    // selectedIndex={this.props.selectedIndex}         
                    // onSelectionChanged={this.selectionChanged}
                >
                    <Item title={this.props.group}>
                        {
                            myItem(this.props.group)
                        }
                    </Item>
                </Accordion>
            </div>
        );
    }

    selectionChanged(e) {
        let newItems = [...this.state.selectedItems];
        e.removedItems.forEach((item) => {
            const index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems.length) {
            newItems = [...newItems, ...e.addedItems];
        }
        this.setState({
            selectedItems: newItems,
        });
    }

    selectedItemsChanged(e) {
        this.setState({
            selectedItems: e.value,
        });
    }

}

export default App;
