import React from 'react';
import { formatNumber } from 'devextreme/localization';

function gridCellData(gridData) {
  // console.log(gridData);
  // console.log(gridData.data[gridData.column.name]);
  return gridData.data[gridData.column.name];
}

export default function ColorCell(cellData) {
  if (cellData.column.name == 'NOX') {
    return (

      <div className={cellData.data.NOX_STATUS == 1 ? 'yellow' : cellData.data.NOX_STATUS == 2 ? 'red' : ""}>
        <div className="diff">{cellData.data.NOX}</div>
        {/* <div className="diff">{Math.abs(gridCellData(cellData).diff).toFixed(2)}</div> */}
      </div>
    );
  }
  else if (cellData.column.name == 'RENDIMENTO_COMBUSTIONE') {
    return (

      <div className={cellData.data.RENDIMENTO_STATUS == 1 ? 'yellow' : cellData.data.RENDIMENTO_STATUS == 2 ? 'red' : ""}>
        <div className="diff">{cellData.data.RENDIMENTO_COMBUSTIONE}</div>
        {/* <div className="diff">{Math.abs(gridCellData(cellData).diff).toFixed(2)}</div> */}
      </div>
    );
  }


}

