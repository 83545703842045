import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import appInfo from '../../app-info';
import './ElencoImpianti.scss';
import DataGrid, {
    Column,
    Pager,
    Paging,
    Item,
    FilterRow,
    Selection,
    FilterPanel,
    StateStoring,
    HeaderFilter,
    GroupPanel,
    ColumnChooser,
    Button,
    Toolbar,
    Editing,
    Popup,
    Scrolling,
    Grouping
} from 'devextreme-react/data-grid';


import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Margin,
    Export,
    Legend,
} from 'devextreme-react/chart';

import PieChart, {
    Label,
    Title,
    Animation,
} from 'devextreme-react/pie-chart';
import DataSource from 'devextreme/data/data_source';

import ButtonToolbar from 'devextreme-react/button';
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import { custom } from 'devextreme/ui/dialog';

import { Tooltip } from 'devextreme-react/tooltip';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { useAuth } from '../../contexts/auth';

export default function App(props) {

    const url = appInfo.urlWebApi;
    const { user } = useAuth();

    var idImpianto;

    function DataSourceGrid() {
        if (user) {
            return createStore({
                key: 'Id',
                loadUrl: `${url}/api/Impianti/Get?internalKey=${user.internalKey}&userType=${user.userType}&id=${user.id}`
            });
        }
        else {
            return null;
        }
    }


    const storeChart = new CustomStore({
        key: 'month',
        load: () => {
            return axios.get(url + '/api/Impianti/GetChart?', { params: { internalKey: user.internalKey, idImpianto: idImpianto } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });
    const dataSourceChart = new DataSource({
        store: storeChart,
        refreshMode: 'reshape'
    });


    const storePie = new CustomStore({
        key: 'month',
        load: () => {
            return axios.get(url + '/api/Impianti/GetPie?', { params: { internalKey: user.internalKey, idImpianto: idImpianto } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });
    const dataSourcePie = new DataSource({
        store: storePie,
        refreshMode: 'reshape'
    });


    function onFocusedRowChanged(e) {
        console.log('onFocusedRowChanged');
        console.log(e);
        
        idImpianto = e.row.key;
        dataSourceChart.reload();
        dataSourcePie.reload();
    }


    function clearLocalStorage() {
        console.log('clean');
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Le personalizzazioni della griglia verrano eliminate. Sicuro di procedere voler procedere?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                localStorage.removeItem('storageImpianto');
            }
        }).then(function () {
            //dataSource.reload();
        })
    }

    function formatText(arg) {
        console.log(arg);
        return `${arg.value}`;
    }

    function isWarningAmministrativoIconVisible(e) {
        console.log(e);
        return e.row.data.hasWarningAmministrativo;
    }




    return (
        <React.Fragment>
            <h2 className={'content-block'}>Elenco impianti di competenza</h2>

            <div className='grid-height'>

                <DataGrid
                    className={'dx-card wide-card height'}
                    dataSource={DataSourceGrid()}
                    showBorders={false}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    onFocusedRowChanged={onFocusedRowChanged}

                    allowColumnReordering={true}
                >
                    <Scrolling showScrollbar='always' mode='standard'></Scrolling>
                    <Selection mode="single" />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={false} />
                    <ColumnChooser enabled={true} mode="select" />
                    <GroupPanel visible={true} emptyPanelText="Trascinare qui le intestazioni di colonna per creare raggruppamenti..." />

                    <StateStoring enabled={true} type="localStorage" storageKey="storageImpianto" />
                    {/* 
                    <Editing mode="popup"
                        useIcons={true}
                        allowUpdating={false}
                        allowAdding={false}
                        allowDeleting={false}>
                        <Popup title="Dettaglio impianto" showTitle={true} width={900} height={700} />
                    </Editing> */}                   
                    <Column type="buttons" width={80} caption="Status" fixed={true} fixedPosition="left">                        
                        <Button hint="Warning amministrativo" icon="warning" visible={isWarningAmministrativoIconVisible} />
                    </Column>

                    <Column caption='IMPIANTO' alignment={"center"}>
                        <Column
                            dataField={'Descrizione'}
                            caption={'Descrizione'}
                            visible={true}
                        />
                        <Column
                            dataField={'Indirizzo'}
                            caption={'Indirizzo'}
                            visible={true}
                            sortOrder="asc"
                        />
                        <Column
                            dataField={'Comune'}
                            caption={'Comune'}
                            width={140}
                            visible={true}
                        />
                        <Column
                            dataField={'Provincia'}
                            caption={'Prov.'}
                            width={90}
                            visible={true}
                        />
                        <Column
                            dataField={'CodiceCatasto'}
                            caption={'Catasto'}
                            width={130}
                            visible={true}
                        />
                        <Column
                            dataField={'Tipo'}
                            caption={'Tipo impianto'}
                            width={250}
                            visible={true}
                            hidingPriority={10}
                        />
                        <Column
                            dataField={'PotenzaTotale'}
                            caption={'Potenza (kW)'}
                            width={140}
                            visible={true}
                            dataType="decimal"
                            precision={2}
                        />
                    </Column>
                    <Column caption='CLIENTE' align='center' visible={!(user.userType == 0)} showInColumnChooser={!(user.userType == 0)} >
                        <Column
                            dataField={'DescrizioneCliente'}
                            caption={'Descrizione'}
                            visible={true}
                            width={300}
                        />
                    </Column>
                    <Column caption='CONTRATTO' align='center'>
                        <Column
                            dataField={'DescrizioneContratto'}
                            caption={'Descrizione'}
                            width={300}
                            visible={true}
                        />
                        <Column
                            dataField={'DescrizioneTipoContratto'}
                            caption={'Tipo'}
                            width={300}
                            visible={true}
                            hidingPriority={5}
                        />
                    </Column>

                    <Toolbar>
                        <Item name="columnChooserButton" />
                        <Item>
                            <ButtonToolbar id="clearLocalStora" icon="clearformat" onClick={clearLocalStorage} text="" />

                            <Tooltip
                                target="#clearLocalStora"
                                showEvent="mouseenter"
                                hideEvent="mouseleave"
                                hideOnOutsideClick={false}
                            >
                                <div>Pulisci personalizzazione griglia</div>
                            </Tooltip>

                        </Item>
                    </Toolbar>

                </DataGrid>
            </div>

            <div className='bottom-place'>
                <div id="chart-demo" className='container-chart'>
                    <Chart
                        className='item-chart'
                        palette="Harmony Light"
                        showTitle="true"
                        title="Andamento richieste"
                        dataSource={dataSourceChart}
                    >
                        <CommonSeriesSettings
                            argumentField="month"
                            type={"splinearea"}
                        />
                        <Series valueField="previousYearValue" name={"Anno precedente"}></Series>
                        <Series valueField="currentYearValue" name={"Anno corrente"}></Series>
                        <ArgumentAxis valueMarginsEnabled={false} />
                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                        />

                        <Export enabled={true} />
                    </Chart>

                    <PieChart
                        id="pie"
                        className='item-pie'
                        dataSource={dataSourcePie}
                        palette="Harmony Light"
                        resolveLabelOverlapping='shift'
                        horizontalAlignment="left"
                    >
                        <Title text="Tipologie" horizontalAlignment="left" />

                        <Series
                            argumentField="type"
                            valueField="value"
                        >
                            <Label visible={false} customizeText={formatText} />
                        </Series>

                        <Export enabled={true} />
                        <Legend visible={true} />
                        <Animation enabled={false} />
                    </PieChart>

                </div>
            </div>
        </React.Fragment>
    );
}




// const priorities = [
//   { name: 'High', value: 4 },
//   { name: 'Urgent', value: 3 },
//   { name: 'Normal', value: 2 },
//   { name: 'Low', value: 1 }
// ];
