import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import appInfo from '../../app-info';
import './ConsumiImpianto.scss';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    Selection,
    StateStoring,
    HeaderFilter,
    GroupPanel,
    Scrolling,
    ColumnChooser,
    Button,
    Editing,
    Popup
} from 'devextreme-react/data-grid';

import DataSource from 'devextreme/data/data_source';

import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { useAuth } from '../../contexts/auth';

import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Margin,
    Export,
    Legend,
} from 'devextreme-react/chart';

export default function App(props) {


    const url = appInfo.urlWebApi;

    var idImpianto;

    const { user } = useAuth();


    function DataSourceGrid() {
        if (user) {
            return createStore({
                key: 'ID_IMPIANTO',
                loadUrl: `${url}/api/ConsumiImpianto/Get?internalKey=${user.internalKey}&userType=${user.userType}&id=${user.id}`
            });
        }
        else {
            return null;
        }
    }

    const storeChart = new CustomStore({
        key: 'month',
        load: () => {
            return axios.get(url + '/api/ConsumiImpianto/GetChart?', { params: { internalKey: user.internalKey, idImpianto: idImpianto } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });


    const dataSourceChart = new DataSource({
        store: storeChart,
        refreshMode: 'reshape'
    })

    function onFocusedRowChanged(e) {
        idImpianto = e.row.key;
        dataSourceChart.reload();
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Consumi ed efficienza energatica impianto</h2>

            <div className='grid-height'>

                <DataGrid
                    className={'dx-card wide-card'}
                    dataSource={DataSourceGrid()}
                    showBorders={false}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    onFocusedRowChanged={onFocusedRowChanged}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                >
                    <Scrolling showScrollbar='always' mode='standard'></Scrolling>
                    <Selection mode="single" />                                    
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <ColumnChooser enabled={true} mode="select" />
                    <GroupPanel visible={true} emptyPanelText="Trascinare qui le intestazioni di colonna per creare raggruppamenti..." />

                    <StateStoring enabled={true} type="localStorage" storageKey="storageConsumi" />

                    {/* <Editing mode="popup"
                        useIcons={true}
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={false}>
                        <Popup title="Dettaglio impianto" showTitle={true} width={900} height={700} />
                    </Editing>
    
                    <Column type="buttons" width={80} caption="EDIT">
                        <Button name="edit" />
                        <Button name="delete" />
                    </Column> */}

                    <Column caption='IMPIANTO' alignment={"center"}>
                        <Column
                            dataField={'CLIENTE'}
                            caption={'Cliente'}
                            visible={!(user.userType == 0)} showInColumnChooser={!(user.userType == 0)}
                        />
                        <Column
                            dataField={'IMPIANTO'}
                            caption={'Impianto'}
                            sortOrder="asc"
                        />
                    </Column>
                    <Column caption='TOTALI' align='center'>
                        <Column
                            dataField={'TOTALI'}
                            caption={'Totale stagione'}
                            width={140}                            
                        />
                        <Column
                            dataField={'DATA_ACCENSIONE'}
                            caption={'Data accensione'}
                            width={150}
                            dataType="date"
                            format="dd-MM-yyyy"
                        />
                        <Column
                            dataField={'DATA_SPEGNIMENTO'}
                            caption={'Data spegnimento'}
                            width={150}
                            dataType="date"
                            format="dd-MM-yyyy"
                        />
                    </Column>
                    <Column caption='CONSUMI' align='center'>
                        <Column
                            dataField={'MONTH_10'}
                            caption={'Ottobre'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_11'}
                            caption={'Novembre'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_12'}
                            caption={'Dicembre'}
                            width={120}


                        />
                        <Column
                            dataField={'MONTH_01'}
                            caption={'Gennaio'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_02'}
                            caption={'Febbraio'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_03'}
                            caption={'Marzo'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_04'}
                            caption={'Aprile'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_05'}
                            caption={'Maggio'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_06'}
                            caption={'Giugno'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_07'}
                            caption={'Luglio'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_08'}
                            caption={'Agosto'}
                            width={120}

                        />
                        <Column
                            dataField={'MONTH_09'}
                            caption={'Settembre'}
                            width={120}

                        />
                    </Column>
                </DataGrid>

            </div>

            <div className='bottom-place'>
                <div id="chart-demo" className='container-chart'>
                    <Chart
                        palette="Harmony Light"
                        className='item-chart-consumi'
                        showTitle="true"
                        title="Andamento consumi"
                        dataSource={dataSourceChart}>
                        <CommonSeriesSettings
                            argumentField="month"
                            type={"splinearea"}
                        />
                        <Series valueField="previousYearValue" name={"Anno precedente"}></Series>
                        <Series valueField="currentYearValue" name={"Anno corrente"}></Series>
                        <ArgumentAxis valueMarginsEnabled={false} />
                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                        />
                        <Margin bottom={20} />
                        <Export enabled={true} />
                    </Chart>                    
                </div>                
            </div>

        </React.Fragment>

    );
}








// const priorities = [
//   { name: 'High', value: 4 },
//   { name: 'Urgent', value: 3 },
//   { name: 'Normal', value: 2 },
//   { name: 'Low', value: 1 }
// ];
