import React from 'react';
import appInfo from '../../app-info';
import './ElencoREE.scss';
import ColorCell from './ColorCell.js';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Scrolling,
  StateStoring,
  ColumnChooser,
  ColumnFixing

} from 'devextreme-react/data-grid';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { useAuth } from '../../contexts/auth';


export default function App(props) {

  const url = appInfo.urlWebApi;
  const { user } = useAuth();

  function DataSource() {
    

    if (user) {
      return createStore({
        key: 'ID',
        loadUrl: `${url}/api/Ree/Get?InternalKey=${user.internalKey}&userType=${user.userType}&id=${user.id}`
      });
    }
    else {
      return null;
    }
  }

  const statoInvio = [
    { Text: 'Da inviare a catasto', Value: 1 },
    { Text: 'Inviato a catasto', Value: 2 },
    { Text: 'Invio a catasto sospeso', Value: 3 },
    { Text: 'Invio a catasto non richiesto', Value: 4 },
    { Text: 'Inviato e consolidato', Value: 5 }
  ];


  return (
    <React.Fragment>
      <h2 className={'content-block'}>Elenco REE</h2>

      <DataGrid
        id='gridContainer'
        className={'dx-card wide-card'}
        dataSource={DataSource()}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
      >
        <Scrolling showScrollbar='always' mode='standard'></Scrolling>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <StateStoring enabled={true} type="localStorage" storageKey="storageRee" />

        <Column dataField={'ID'} width={90} hidingPriority={2} />
        <Column
          dataField={'CLIENTE'}
          witdh={300}
          caption={'Cliente'}
          visible={!(user.userType == 0)} showInColumnChooser={!(user.userType == 0)}
        />
        <Column
          dataField={'IMPIANTO'}
          witdh={300}
          caption={'Impianto'}
        />
        <Column
          dataField={'CODICE_CATASTO'}
          caption={'Codice catasto'}
        />
        <Column
          dataField={'COMPONENTE'}
          witdh={300}
          caption={'Componente'}
        />
        <Column
          dataField={'STATO_INVIO'}
          caption={'Stato invio'}
        >
          <Lookup dataSource={statoInvio} valueExpr="Value" displayExpr="Text" />
        </Column>
        <Column
          dataField={'DATA_ESECUZIONE'}
          caption={'Data esecuzione'}
          dataType="date"
          format="dd/MM/yyyy"
        />
        <Column
          dataField={'DATA_INVIO_CATASTO'}
          caption={'Data invio'}
          dataType="date"
          format="dd/MM/yyyy"
        />
        <Column
          dataField={'DATA_CONSOLIDAMENTO'}
          caption={'Data cons.'}
          dataType="date"
          format="dd/MM/yyyy"
        />
        <Column
          dataField={'N_MODULO'}
          caption={'N mod.'}

        />
        <Column
          dataField={'NOX'}
          caption={'NOX (mg/kwh)'}
          cellRender={ColorCell}
          fixed={true} fixedPosition="right"
          alignment='center'
        />
        <Column
          dataField={'RENDIMENTO_COMBUSTIONE'}
          caption={'Rendimento (%)'}
          cellRender={ColorCell}
          fixed={true} fixedPosition="right"
          alignment='center'
        />
      </DataGrid>
    </React.Fragment>
  );
}



