import React, { useState, useRef, useCallback } from 'react';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { CustomRule, PatternRule } from 'devextreme-react/validator';

import './login-form.scss';

export default function () {
    const history = useHistory();
    const { signIn } = useAuth();
    const [loading, setLoading] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search);
    const username = queryParameters.get("a");
    const pwd = queryParameters.get("b");
    const target = queryParameters.get("c");

    const formData = (username == null && pwd == null) ? useRef({}) : useRef({ username: username, password: pwd, target: target });

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const { username, password, target } = formData.current;
        setLoading(true);

        const result = await signIn(username, password, (username != null && pwd != null), target);
        if (!result.isOk) {
            setLoading(false);
            notify(result.message, 'error', 2000);
        }
    }, [signIn]);

    const onCreateAccountClick = useCallback(() => {
        history.push('/create-account');
    }, [history]);

    function customValidation(params) {        
        var result = true;
        if (target == null) {            
            result = params.formItem.dataField == 'password' && (params.value ?? '').toString().length > 0;
        }
        else {
            result = true;
        }
        return result;
    }

    return (
        <form className={'login-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                >
                    <RequiredRule message="Username is required" />
                    <Label visible={false} />
                </Item>
                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
                >
                    <CustomRule
                        type="custom"
                        message={"Password is required"}
                        reevaluate={true}
                        validationCallback={customValidation}
                    />

                    <Label visible={false} />
                </Item>
                <Item
                    dataField={'rememberMe'}
                    editorType={'dxCheckBox'}
                    editorOptions={rememberMeEditorOptions}
                >
                    <Label visible={false} />
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                    : 'Sign In'
                            }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                <Item>
                    <div className={'link'}>
                        <Link to={'/reset-password'}>Forgot password?</Link>
                    </div>
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        text={'Create an account'}
                        width={'100%'}
                        onClick={onCreateAccountClick}
                    />
                </ButtonItem>
            </Form>
        </form>
    );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
