import defaultUser from '../utils/default-user';
import appInfo from '../app-info';
import axios from 'axios';


export async function signIn(username, password, sup, targetDb) {
    var signInResponse = null;

    console.log('signIn');
    console.log(targetDb)


    const instance = axios.create({
        baseURL: appInfo.urlWebApi,
        timeout: 50000,
        headers: { 'X-Custom-Header': 'foobar' }
    });

    var apiCall = '/api/Login/Login';

    if (sup && targetDb == null) apiCall = '/api/Login/LoginImpersonification';
    if (sup && targetDb != null) apiCall = '/api/Login/LoginUtente';

    var params = { Username: username, Password: password };

    if (sup && targetDb != null) params = { Username: username, Password: password, InternalKey: targetDb };

    console.log(apiCall);
    console.log(params);


    await instance.get(apiCall, {
        params: params
    })
        .then(function (response) {

            if (response.data.result === 1)
                signInResponse = { isOk: true, data: response.data.userData };
            else
                signInResponse = { isOk: false, message: response.data.message, data: null };
        })
        .catch(function () {

            signInResponse = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
        })
        .finally(function ()
        { });

    return signInResponse;
}

export async function getUser(username, password) {
    try {
      var signInResponse = null;

      const instance = axios.create({
          baseURL: appInfo.urlWebApi,
          timeout: 50000,
          headers: { 'X-Custom-Header': 'foobar' }
      });
  
      await instance.get('/api/Login/LoginImpersonification', {
          params: { username: username, password: password }
      })
          .then(function (response) {
  
              if (response.data.result === 1)
                  signInResponse = { isOk: true, data: response.data.userData };
              else
                  signInResponse = { isOk: false, message: response.data.message, data: null };
          })
          .catch(function () {
  
              signInResponse = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
          })
          .finally(function ()
          { });
  
      return signInResponse;        
    }
    catch {
        return {
            isOk: false
        };
    }
}

export async function createAccount(email, password) {
  try {

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
