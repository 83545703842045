import React from 'react';
import { formatNumber } from 'devextreme/localization';

function gridCellData(gridData) {
  // console.log(gridData);
  return gridData.text;
}

export default function ColorCell(cellData) {
  if (cellData.column.name == 'WF_STATO_CALC') {
    return (
      <div className={cellData.data.WF_STATO_CALC == 4 ? 'green' : cellData.data.WF_STATO_CALC == 8 ? 'red' : cellData.data.WF_STATO_CALC == 3 ? 'yellow' : cellData.data.WF_STATO_CALC == 10 ? 'lightblue' : cellData.data.WF_STATO_CALC == 18 ? 'bluette' : ""}>
        <div className="diff">{gridCellData(cellData)}</div>        
      </div>
    );
  }
  
 

}

