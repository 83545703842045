import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import notify from 'devextreme/ui/notify';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async function () {
                       
            setLoading(false);
          
        })();
    }, []);

    const signIn = useCallback(async (username, password, sup, targetDb) => {        
        const result = await sendSignInRequest(username, password, sup, targetDb);
        if (result.isOk) {
            setUser(result.data);
        }

        return result;
    }, []);

    const signOut = useCallback(() => {
        setUser();
    }, []);


    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
    );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
