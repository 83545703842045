import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';

export default function () {

    const queryParameters = new URLSearchParams(window.location.search);
    const username = queryParameters.get("a");
    const pwd = queryParameters.get("b");
    const target = queryParameters.get("c");

  return (
      <SideNavBarLayout title={appInfo.title +  " v.1.50" + " -  " + appInfo.company } >
      <Switch>
        {routes.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />  
        ))}
              <Redirect to={target == null ? '/home' : '/flussoApprovativo'} />
      </Switch>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        {/*<br />*/}
        {/*All trademarks or registered trademarks are property of their*/}
        {/*respective owners.*/}
      </Footer>
    </SideNavBarLayout>
  );
}