import React from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import appInfo from '../../app-info';
import { useAuth } from '../../contexts/auth';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chunks: [] };
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);
    this.onSelectedFilesChanged = this.onSelectedFilesChanged.bind(this);
    this.onFilesUploaded = this.onFilesUploaded.bind(this);
  }



  render() {
    const url = appInfo.urlWebApi;

    console.log('fileupload');
    console.log(this.props.urlUpload);

    return (
      <React.Fragment>
        <FileUploader
          name="file"
          accept="*"
          uploadUrl={this.props.urlUpload}
          uploadMode="instantly"
          chunkSize={10000000}
          labelText={this.props.multiple ? "Trascina qui uno o più file" : "Trascina qui il file"}
          showFileList={this.props.showFileList}
          multiple={this.props.multiple}
          onFilesUploaded={this.onFilesUploaded}
          onUploadStarted={this.onUploadStarted}
          onValueChanged={this.onSelectedFilesChanged}
          onProgress={this.onUploadProgress} />
        {/* <span className="note">Massima dimensione file: <span>4 MB.</span></span> */}
      </React.Fragment>
    );
  }

  onUploadProgress(e) {
    const chunk = {
      segmentSize: e.segmentSize,
      bytesLoaded: e.bytesLoaded,
      bytesTotal: e.bytesTotal,
    };
    this.setState({ chunks: [...this.state.chunks, chunk] });
  }

  onUploadStarted() {
    this.setState({ chunks: [] });
  }

  getValueInKb(value) {
    return `${(value / 1024).toFixed(0)}kb`;
  }

  onSelectedFilesChanged(e) {
    this.setState({ selectedFiles: e.value });
    const reader = new FileReader();
    reader.readAsBinaryString(e.value[0]);
  }

  onFilesUploaded = (e) => {
    console.log('onFilesUploaded');
    console.log(e);
    this.props.onChange(true, null);
  };


}

export default App;
