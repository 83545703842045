import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import 'devextreme/data/odata/store';
import axios from 'axios';
import appInfo from '../../app-info';
import './pie-multiple.scss';

import Chart, {
  ValueAxis,
  ArgumentAxis,
  CommonPaneSettings,
  Grid,
  Series,
  Label,
  Size,
  Border,
  Tooltip,
  Margin,
  Export,
  Legend,
  AdaptiveLayout,
  LoadingIndicator,
} from 'devextreme-react/chart';



import PieChart, {
  Title,
  Animation,
} from 'devextreme-react/pie-chart';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import { useAuth } from '../../contexts/auth';

import SelectBox from 'devextreme-react/select-box';

// import { months } from './data.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ruoli: [] };

  }

  async  UNSAFE_componentWillMount() {
    const url = appInfo.urlWebApi;
    const user = this.props.user;

    var result = [];

    const instance = axios.create({
      baseURL: url,
      timeout: 50000,
      headers: { 'X-Custom-Header': 'foobar' }
    });

    await instance.get('/api/Documenti/GetRuoli', {
      params: { internalKey: user.internalKey, userType: user.userType, id: user.id }
    })
      .then(function (response) {
        (response.data).forEach(element => {
          result.push({ key: element.ID_RUOLO, value: element.DESCRIZIONE });
        });


      })
      .catch(function () {

      })
      .finally(function () { });

    this.setState({ ruoli: result });
  }

  componentDidMount() {

  }

  


  render() {
    const url = appInfo.urlWebApi;
    const user = this.props.user;

    function DataSourceChart(idRuolo) {
      if (user) {
        return createStore({
          key: 'Id',
          loadUrl: `${url}/api/Documenti/GetPie?internalKey=${user.internalKey}&userType=${user.userType}&id=${user.id}&idRuolo=${idRuolo}`
        });
      }
      else {
        return null;
      }
    }

    function customizePoint(point) {
      const color = point.series.getPointsByArg(point.argument)[0].getColor();
      let fillId;
      switch (point.argument) {
        case 'Assenti':
          fillId = 'yellow';
          break;
        case 'Scaduti':
          fillId = 'red';
          break;
        case 'Validi':
          fillId = 'green';
          break;        
        default:
          break;
      }
      return { color: fillId };
    }
    
    return (this.state.ruoli).map(item => (

        <PieChart
          id={"pie" + item.key.toString()}
          className='item-pie'
          dataSource={DataSourceChart(item.key)}
          palette="Harmony Light"
          resolveLabelOverlapping='shift'
          horizontalAlignment="left"
          customizePoint={customizePoint}
        >
          <Title text={item.value} horizontalAlignment="left" />

          <Series
            argumentField="type"
            valueField="value"
          >
            {/* <Label visible={false} customizeText={formatText} /> */}
          </Series>


          <Export enabled={true} />
          <Legend visible={true} />
          <Animation enabled={false} />
        </PieChart>

    ))

   

  }


}



export default App;
