import React from 'react';
import appInfo from '../../app-info';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup
} from 'devextreme-react/data-grid';

import { createStore } from 'devextreme-aspnet-data-nojquery';

const url = appInfo.urlWebApi;

const dataSource = createStore({
    key: 'Id',
    loadUrl: `${url}/Impianti`
    // insertUrl: `${url}/InsertOrder`,
    // updateUrl: `${url}/UpdateOrder`,    
    // deleteUrl: `${url}/DeleteOrder`,
    // onBeforeSend: (method, ajaxOptions) => {
    //     ajaxOptions.xhrFields = { withCredentials: false };
    // },
});


export default () => (
  <React.Fragment>
    <h2 className={'content-block'}>Elenco impianti di competenza</h2>

    <DataGrid
      className={'dx-card wide-card'}
      dataSource={dataSource}
      showBorders={false}
      focusedRowEnabled={true}
      defaultFocusedRowIndex={0}
      columnAutoWidth={true}
      columnHidingEnabled={true}
    >
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector={true} showInfo={true} />
      <FilterRow visible={true} />

      <Column dataField={'Id'} width={90} hidingPriority={2} />
      <Column
        dataField={'Descrizione'}
        width={190}
        caption={'Descrizione'}
        hidingPriority={4}
      />
      <Column
        dataField={'CodiceCatasto'}
        caption={'Codice catasto'}
        hidingPriority={6}
      />
      <Column
        dataField={'Indirizzo'}
        caption={'Indirizzo'}
        hidingPriority={7}
      />
      <Column
        dataField={'ClienteDescrizione'}
        caption={'Descrizione cliente'}
        hidingPriority={8}
      />
      {/* <Column
        dataField={'Task_Priority'}
        caption={'Priority'}
        hidingPriority={5}
      >
        <Lookup
          dataSource={priorities}
          valueExpr={'value'}
          displayExpr={'name'}
        />
      </Column> */}
      {/* <Column
        dataField={'ResponsibleEmployee.Employee_Full_Name'}
        caption={'Assigned To'}
        allowSorting={false}
        hidingPriority={7}
      /> */}
      {/* <Column
        dataField={'Task_Start_Date'}
        caption={'Start Date'}
        dataType={'date'}
        hidingPriority={3}
      />
      <Column
        dataField={'Task_Due_Date'}
        caption={'Due Date'}
        dataType={'date'}
        hidingPriority={4}
      />
      <Column
        dataField={'Task_Priority'}
        caption={'Priority'}
        name={'Priority'}
        hidingPriority={1}
      />
      <Column
        dataField={'Task_Completion'}
        caption={'Completion'}
        hidingPriority={0}
      /> */}
    </DataGrid>
  </React.Fragment>
);



// const priorities = [
//   { name: 'High', value: 4 },
//   { name: 'Urgent', value: 3 },
//   { name: 'Normal', value: 2 },
//   { name: 'Low', value: 1 }
// ];
