import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import './FlussoApprovativo.scss';
import axios from 'axios';
import appInfo from '../../app-info';
import notify from 'devextreme/ui/notify';

import logo from '../../Images/logo-completo.jpg';
import shieldGreen from '../../Images/Security_Security.svg';
import shieldYellow from '../../Images/Security_Security bicolor 2.svg';
import shieldRed from '../../Images/Security_Security red.svg';

import { useAuth } from '../../contexts/auth';
import Verticalmemolist from '../../components/vertical-memo-list/vertical-memo-list';
import { AccordionForm } from '../../components';
import ScrollView from 'devextreme-react/scroll-view';
import { Accordion } from 'devextreme-react';
import Button from 'devextreme-react/button';
import MultiView, { Item } from 'devextreme-react/multi-view';
import { StateStoring } from 'devextreme-react/data-grid';
import { TextArea } from 'devextreme-react/text-area';
import ValidationGroup from 'devextreme-react/validation-group';

import { custom } from 'devextreme/ui/dialog';

import {
    Validator,
    CustomRule,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule,
} from 'devextreme-react/validator';

const url = appInfo.urlWebApi;

export default function App() {

    const { user } = useAuth();
    const [selectedIndex, setSelectIndex] = useState(0);
    const [{ selectedTitolo, selectedCorpo, doValidation, selectedStatus }, setSelectText] = useState({});
    const [isEditable, setEditable] = useState(true);
    const [annotazione, setAnnotazione] = useState(null);

    async function DataSourceRiepilogo() {
        var result = [];

        console.log('DataSourceRiepilogo1');
        const queryParameters = new URLSearchParams(window.location.search);
        const prev = queryParameters.get("d");

        if (user) {

            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });
            await instance.get('/api/FlussiApprovativi/GetDati', {
                params: { internalKey: user.internalKey, idPreventivo: prev }
            })
                .then(function (response) {
                    console.log('response')
                    console.log(response.data);
                    result = response.data;
                })
                .catch(function () {
                })
                .finally(function () { });

            this.setState({ ruoli: result });
        }
    }

    useEffect(async () => {


    })


    function goToForwardIndex(e) {
        console.log('goToSelectedIndex');
        console.log(e);
        console.log(selectedIndex);

        var index = selectedIndex + 1;
        console.log(index);

        if (index === 1) {
            console.log(e.element.id);
            switch (e.element.id) {
                case 'btnAccetta':
                    setSelectText({ selectedTitolo: 'PREVENTIVO ACCETTATO', selectedCorpo: 'Il preventivo passa al successivo step approvativo previsto.', doValidation: false, selectedStatus: 20 });
                    console.log('a');
                    break;
                case 'btnRifiuta':
                    setSelectText({ selectedTitolo: 'PREVENTIVO RIFIUTATO', selectedCorpo: 'Il preventivo viene riportato all\'attenzione del compilatore e retrocesso in stato "In compilazione"', doValidation: true, selectedStatus: 16 });
                    break;
                case 'btnAnnulla':
                    setSelectText({ selectedTitolo: 'PREVENTIVO ANNULLATO', selectedCorpo: 'Il preventivo viene annullato definitivamente.', doValidation: true, selectedStatus: 8 });
                    break;
            }
        }

        setSelectIndex(index);
    }
    function goToBackIndex(e) {
        console.log('goToSelectedIndex');
        console.log(e);
        setSelectIndex(selectedIndex - 1);
    }

    async function SetFlussoApprovativo() {
        console.log('SetFlussoApprovativo');
        const queryParameters = new URLSearchParams(window.location.search);
        const prev = queryParameters.get("d");
        // console.log(queryParameters);
        // console.log(prev);
        // console.log(selectedStatus);
        // console.log(annotazione);
        // console.log(selectedIndex);
        // console.log(user);

        var result = 0;

        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });


            await instance.post('/api/FlussiApprovativi/SetFlussoApprovativo', null, {
                params: { internalKey: user.internalKey, idPreventivo: prev, idUtente: user.id, nota: annotazione, status: selectedStatus }
            })
                .then(function (response) {
                    console.log(response);
                    result = response.data;
                })
                .catch(function () {

                })
                .finally(function () { });
        }
        else {
            return result;
        }

        return result;
    }


    const validate = useCallback(async (params) => {
        const result = params.validationGroup.validate();
        var returnValue = 0;
        if (result.isValid) {
            console.log('valido');
            console.log(params);
            console.log(selectedStatus);

            await SetFlussoApprovativo().then(function (result) {
                returnValue = result;
            });

            if (returnValue === 1 || returnValue === 2) {
                setSelectIndex(2);
            }
            else {
                let customDeleteConfirmDialog = custom({
                    showTitle: false,
                    messageHtml: "Errore durante il cambio stato.",
                    buttons: [{
                        text: "Ok",
                        onClick: () => true
                    }]
                })

                await customDeleteConfirmDialog.show().then((dialogResult) => {
                    if (dialogResult) {
                        return;
                    }
                }).then(function () {
                    return;
                })
            }

        }
        else {
            console.log('NON valido');
        }
    }, [selectedTitolo, selectedStatus, annotazione, selectedIndex]);
    const handleAnnotazioneChange = useCallback((e) => {
        console.log('handleAnnotazioneChange');
        setAnnotazione(e.value);
        console.log(annotazione);
    }, [annotazione]);

    function customValidation(params) {
        var result = true;

        if (doValidation) {
            if (params.value == null)
                result = false;
            else if (params.value.trim().length == 0)
                result = false;
        }

        return result;
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Approvazione preventivo</h2>

            <div className={'dx-card responsive-paddings full-background'}>
                <ScrollView width='100%' height='100%'>
                    <div className={'right-padding-r'}>
                        <MultiView
                            animationEnabled={true}
                            loop={false}
                            swipeEnabled={false}
                            selectedIndex={selectedIndex}
                        >
                            <Item>
                                <div>
                                    <AccordionForm user={user} group={"TESTATA"} collapsible={true} selectedIndex={0} />
                                </div>
                                <div>
                                    <AccordionForm user={user} group={"DETTAGLIO"} collapsible={true} selectedIndex={0} />
                                </div>
                                {isEditable ? (
                                    <div>
                                        <div>
                                            <div>
                                                <Button id='btnAccetta' type="success" icon="check" width={'100%'} text='ACCETTA' onClick={goToForwardIndex}></Button>
                                            </div>
                                            <div>
                                                <Button id='btnRifiuta' type="danger" icon="remove" width={'100%'} text='RIFIUTA' onClick={goToForwardIndex}></Button>
                                            </div>
                                            <div>
                                                <Button id='btnAnnulla' type="normal" icon="clearsquare" width={'100%'} text='ANNULLA' onClick={goToForwardIndex}></Button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}



                            </Item>
                            <Item>
                                <ValidationGroup>
                                    <p><b>{selectedTitolo}</b></p>
                                    <p>{selectedCorpo}</p>
                                    <TextArea value={annotazione} onValueChanged={handleAnnotazioneChange}>
                                        <Validator>
                                            <CustomRule
                                                type="custom"
                                                message={"Annotazione obbligatoria"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                        </Validator>


                                    </TextArea>
                                    {isEditable ? (
                                        <div>
                                            <Button id='btnValida' type="success" icon="check" width={'100%'} text='OK' onClick={validate}></Button>
                                        </div>
                                    ) : null}
                                    <div>
                                        <Button id='btnCancella' type="danger" icon="revert" width={'100%'} text='CANCELLA' onClick={goToBackIndex}></Button>
                                    </div>
                                </ValidationGroup>
                            </Item>
                            <Item>
                                <div>
                                    <p>Attività completata</p>
                                </div>
                            </Item>

                        </MultiView>
                    </div>
                </ScrollView>
            </div>





        </React.Fragment>
    );
}







