import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import './Documenti.scss';
import axios from 'axios';
import appInfo from '../../app-info';
import ColorCell from './ColorCell.js';
import { custom } from 'devextreme/ui/dialog';
import Form, { Item, EmptyItem, TabbedItem, Tab, TabPanelOptions, GroupItem, Label } from 'devextreme-react/form';
// import { FileUploader } from 'devextreme-react/file-uploader';
import Verticalmemolist from '../../components/vertical-memo-list/vertical-memo-list';
import { FileUploaderForm } from '../../components';
import { PieMultiple } from '../../components';
import SwitchCell from '../../components/toogle-button/toogle-button';
import { CheckBox } from 'devextreme-react/check-box';
import Guid from 'devextreme/core/guid';
import ScrollView from "devextreme/ui/scroll_view"

import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    ColumnChooser,
    ColumnFixing,
    StateStoring,
    HeaderFilter,
    GroupPanel,
    Button,
    Editing,
    RequiredRule,
    FilterPanel,

} from 'devextreme-react/data-grid';

import {
    validationRules,
    positionEditorOptions,
    notesEditorOptions,
    phoneEditorOptions
} from './../../ValidationRulesAndEditorOptions.js';

import Popup, { ToolbarItem } from 'devextreme-react/popup';
import validationEngine from 'devextreme/ui/validation_engine';
import AddRowButton from 'devextreme-react/button';
import 'devextreme-react/text-area';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { useAuth } from '../../contexts/auth';
import { formatDate } from 'devextreme/localization';
import { ToggleButtonCell } from '../../components';




export default function App(props) {

    const validationGroupName = "gridForm";

    const url = appInfo.urlWebApi;
    const { user } = useAuth();
    var extraParam;

    const store = new CustomStore({
        key: 'ID',
        load: (loadOptions) => {
            return axios.get(url + '/api/Documenti/Get?key=', { params: { internalKey: user.internalKey, userType: user.userType, id: user.id } });
        },
        insert: (values) => {
            return axios.put(url + '/api/Documenti/Put?', null, { params: { key: values.ID, values: JSON.stringify(values), internalKey: user.internalKey, userType: user.userType, id: user.id, newid: newGuid } });
        },
        update: (key, values) => {
            return axios.put(url + '/api/Documenti/Update?', null, { params: { key: key, values: JSON.stringify(values), internalKey: user.internalKey, newid: newGuid } });
        },
        remove: (key) => {
            return axios.delete(url + '/api/Documenti/Delete', { params: { internalKey: user.internalKey, extraParam: extraParam } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const dataSource = new DataSource({
        store: store,
        refreshMode: 'reshape',
    });

    const gridRef = useRef(null);
    const [{ isNewRecord, formData, visible, newGuid }, setPopupState] = useState({});
    const [chartRuoli, setCharRuolitState] = useState([]);

    // const childRef = useRef();
    // childRef = CreateChartByRules();



    //   const chartRuolis = useMemo(async () => {
    //     return CreateChartByRules();
    // });

    // useEffect(async() => {

    //     setCharRuolitState(CreateChartByRules());

    // })

    async function CreateChartByRules() {
        if (user) {

            var result = [];

            const instance = axios.create({
                baseURL: url,
                timeout: 50000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/api/Documenti/GetRuoli', {
                params: { internalKey: user.internalKey, userType: user.userType, id: user.id }
            })
                .then(function (response) {
                    console.log(response);
                    (response.data).forEach(element => {
                        result.push(element.ID_RUOLO);
                    });


                })
                .catch(function () {
                    result = [];
                })
                .finally(function () { });

            console.log(result);
            return result;
        }
        else {
            return null;
        }
    }


    const showPopup = useCallback((isNewRecord, formData) => {
        var newGuid = new Guid().toString();
        console.log(formData);
        setPopupState({ isNewRecord, formData, visible: true, newGuid });
    }, []);

    const hidePopup = useCallback(() => {
        setPopupState({ visible: false });
    }, []);

    const confirmChanges = useCallback(async () => {
        const result = validationEngine.validateGroup(validationGroupName);

        if (!result.isValid)
            return;

        if (isNewRecord)
            await store.insert(formData);
        else
            await store.update(formData["ID"], formData);

        await store.load();

        hidePopup();

    }, [isNewRecord, formData, hidePopup]);


    const confirmBtnOptions = useMemo(() => {
        return {
            text: "Salva",
            type: "success",
            onClick: confirmChanges
        }
    }, [confirmChanges]);

    const cancelBtnOptions = useMemo(() => {
        return {
            text: "Annulla",
            onClick: hidePopup
        }
    }, [hidePopup]);

    const editRow = useCallback((e) => {
        showPopup(false, { ...e.row.data });
    }, [showPopup]);



    const addRow = useCallback((e) => {
        showPopup(true, { ...e.row.data });
    }, [showPopup]);

    function deleteRow(e) {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Sicuro di voler rimuore questo documento?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                extraParam = e.row.data.ID_ALLEGATO;
                store.remove(e.row.data.ID);
            }
        }).then(function () {
            dataSource.reload();
        })
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function calculateCellValue(data) {
        var status;

        var dateNow = new Date()
        dateNow.setHours(0, 0, 0);

        if (data.ID_ALLEGATO != 0 && data.ID_ALLEGATO != null) {
            if (data.DATA_SCADENZA == null)
                status = 'Non presente';
            else if (Date.parse(data.DATA_SCADENZA) < Date.parse(dateNow))
                status = 'Scaduto';
            else if (Date.parse(data.DATA_SCADENZA) >= Date.parse(dateNow) && Date.parse(data.DATA_SCADENZA) < Date.parse(addDays(dateNow, 15)))
                status = 'In scadenza';
            else
                status = 'Valido';
        }
        else {
            status = 'Non presente';
        }

        return status;
    }



    function base64ToArrayBuffer(base64) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }



    async function GetDocumento(ID_ALLEGATO) {
        console.log('inizio');

        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 50000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/api/Documenti/DownloadFile', {
                params: { internalKey: user.internalKey, idAllegato: ID_ALLEGATO }
            })
                .then(function (response) {
                    console.log(response);

                    var bytes = base64ToArrayBuffer(response.data.dataObject);

                    const url = window.URL.createObjectURL(new Blob([bytes]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', response.data.fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(function () {
                    console.log('errore');
                    // imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });
        }
        else {
            return null;
        }
    }

    function getUrlUpload() {
        return url + '/api/Documenti/Post?internalKey=' + user.internalKey + '&newid=' + newGuid;
    }

    function downloadFile(e) {
        console.log('inizio download');
        console.log(e.row.data.ID_ALLEGATO);
        GetDocumento(e.row.data.ID_ALLEGATO);
        console.log('fine download')
    }

    function onChangeFileUpload(result, value) {
        // dataSourceAllegati.reload();
    }

    //INIT GRID
    function onAllowAdding(e) {
        return !(e.row.data.ID_ALLEGATO != 0 && e.row.data.ID_ALLEGATO != null);
    }
    function onAllowDeleting(e) {
        return (e.row.data.ID_ALLEGATO != 0 && e.row.data.ID_ALLEGATO != null);
    }
    function onAllowDownloading(e) {
        return (e.row.data.ID_ALLEGATO != 0 && e.row.data.ID_ALLEGATO != null);
    }
    function onAllowUpdating(e) {
        return (e.row.data.ID_ALLEGATO != 0 && e.row.data.ID_ALLEGATO != null);
    }
    //END



    return (
        <React.Fragment>
            <h2 className={'content-block'}>Elenco documenti</h2>
            <div className='grid-height'>
                <DataGrid
                    id='gridContainer'
                    className={'dx-card wide-card'}
                    dataSource={dataSource}
                    ref={gridRef}
                    showBorders={false}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                >


                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <ColumnChooser enabled={true} mode="select" />
                    <GroupPanel visible={true} emptyPanelText="Trascinare qui le intestazioni di colonna per creare raggruppamenti..." />

                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <FilterRow visible={true} />

                    <Column dataField={'ID'} width={90} hidingPriority={2} visible={false} />

                    <Column type="buttons" width={120} caption="AZIONI">
                        <Button name="add" visible={onAllowAdding} onClick={addRow} />
                        <Button name="edit" visible={onAllowUpdating} onClick={editRow} />
                        <Button icon="download" visible={onAllowDownloading} onClick={downloadFile} />
                        <Button name="delete" visible={onAllowDeleting} onClick={deleteRow} />
                    </Column>

                    <Column caption='DOCUMENTI' alignment={"center"}>

                        <Column
                            dataField={'DESCRIZIONE'}
                            witdh={300}
                            caption={'Descrizione impianto'}
                        />
                        <Column
                            dataField={'RUOLI'}
                            witdh={300}
                            caption={'Ruoli'}
                        />
                        <Column
                            dataField={'DATA_CREATION'}
                            width={150}
                            caption={'Data caricamento'}
                            dataType="date"
                            format="dd-MM-yyyy"
                        />
                        <Column
                            dataField={'DATA_SCADENZA'}
                            width={150}
                            caption={'Data scadenza'}
                            dataType="date"
                            format="dd-MM-yyyy"
                        />
                        <Column
                            name={'STATUS'}
                            minWidth={130}
                            caption={'Stato'}
                            visible={true}
                            calculateCellValue={calculateCellValue}
                            cellRender={ColorCell}
                        ></Column>
                        <Column
                            dataField={'NOTE'}
                            caption={'Annotazioni aggiuntive'}
                            hidingPriority={-1}
                        />
                    </Column>

                </DataGrid>
            </div>
            <div className='bottom-place'>
                <div id="chart-demo" className='container-chart'>
                    <PieMultiple user={user}></PieMultiple>
                </div>
            </div>




            {visible ? (
                <Popup
                    title={formData.Descrizione}
                    hideOnOutsideClick={true}
                    visible={true}
                    width={900}
                    height={"auto"}
                    onHiding={hidePopup}
                >
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={confirmBtnOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelBtnOptions}
                    />

                    <Form colCount={3}
                        encType="multipart/form-data"
                        validationGroup={validationGroupName}
                        formData={formData}
                    >
                        <Item colSpan={3} visible={isNewRecord || calculateCellValue(formData) == 'Scaduto'  ? true : false}>
                            <FileUploaderForm showFileList={true} multiple={false} urlUpload={getUrlUpload()} userKey={user.key} dataField="ID" onChange={onChangeFileUpload}>
                                <RequiredRule />
                            </FileUploaderForm>
                        </Item>

                        <EmptyItem colSpan={3}></EmptyItem>

                        <Item dataField="DATA_SCADENZA" editorType="dxDateBox" editorOptions={{ dateSerializationFormat: 'yyyy-MM-dd', displayFormat: 'dd-MM-yyyy' }} colSpan={1}>
                            <RequiredRule />
                        </Item>

                        <Item dataField="ASSUNZIONE_RESP" editorType="dxCheckBox" colSpan={2} editorOptions={{ iconSize: 25 }} >
                            <Label text={`Assunzione di responsabilità per la veridicità dal documento e della data di scadenza `} />
                            <RequiredRule />
                        </Item>

                        <EmptyItem colSpan={3}></EmptyItem>

                        <Item dataField="NOTE" editorType="dxTextArea" colSpan={3} editorOptions={{ height: 240 }}>
                        </Item>


                    </Form>

                </Popup>
            ) : null}

        </React.Fragment>
    );
}










