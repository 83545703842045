import { withNavigationWatcher } from './contexts/navigation';
import * as pages from './pages';

const routes = [
    {
        path: '/home',
        component: pages.HomePage
    },
    {
        path: '/elencoImpianti',
        component: pages.ElencoImpiantiPage
    },
    {
        path: '/richiesteIntervento',
        component: pages.ElencoRichiesteInterventiPage  
    },
    {
        path: '/richiestePreventivi',
        component: pages.ElencoRichiestePreventiviPage
    },
    {
        path: '/ree',
        component: pages.ElencoREEPage
    },
    {
        path: '/ConsumiImpianto',
        component: pages.ConsumiImpianto
    },
    {
        path: '/documenti',
        component: pages.DocumentiPage
    },
    {
        path: '/iVulcanoSmart'
    },
    {
        path: '/profile',
        component: pages.ProfilePage
    },
    {
        path: '/manuale',
    },
    {
        path: '/flussoApprovativo',
        component: pages.FlussoApprovativoPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        component: withNavigationWatcher(route.component)
    };
});
