// CLIENTI

export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            },
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoRee = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoReeNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoLet = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoLetNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            }
        ]
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoConsultazione = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoConsultazioneNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            },
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];

// AMMINISTRATORI

export const navigationAmm = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            },
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            },
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoRee = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoReeNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoReeNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoReeNoDocNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoLet = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoLetNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            }
        ]
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoLetNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            }
        ]
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoLetNoDocNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            }
        ]
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoConsultazione = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoConsultazioneNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoConsultazioneNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoConsultazioneNoDocNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            },
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationAmmNoDocNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Impianti',
        path: '/elencoImpianti',
        icon: 'globe'
    },
    {
        text: 'Richieste interventi',
        path: '/richiesteIntervento',
        icon: 'tel'
    },
    {
        text: 'Richieste preventivi',
        path: '/richiestePreventivi',
        icon: 'money'
    },
    {
        text: 'Consultazione',
        icon: 'folder',
        items: [
            {
                text: 'R.E.E.',
                path: '/ree',
                icon: 'favorites'
            },
            {
                text: 'Consumi',
                path: '/ConsumiImpianto',
                icon: 'money'
            }
        ]
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];


// ATTORI

export const navigationActor = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    //{
    //    text: 'Attivit� in corso',
    //    path: '/home',
    //    icon: 'home'
    //},    
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationActorNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    //{
    //    text: 'Attivit� in corso',
    //    path: '/home',
    //    icon: 'home'
    //},    
    {
        text: 'Documenti',
        path: '/documenti',
        icon: 'doc'
    },
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationActorNoDoc = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'iVulcanoSmart',
        path: '/iVulcanoSmart',
        icon: 'link'
    },
    //{
    //    text: 'Attivit� in corso',
    //    path: '/home',
    //    icon: 'home'
    //},        
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];
export const navigationActorNoDocNoMobile = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    //{
    //    text: 'Attivit� in corso',
    //    path: '/home',
    //    icon: 'home'
    //},        
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    {
        text: 'Manuale',
        path: '/manuale',
        icon: 'help'
    }
];


// UTENTI

export const navigationUtenti = [
    {
        text: 'Flusso approvativo',
        path: '/flussoAppprovativo',
        icon: 'home'
    },
    //{
    //    text: 'Attivit� in corso',
    //    path: '/home',
    //    icon: 'home'
    //},        
    // {
    //     text: 'Profilo',
    //     path: '/profile',
    //     icon: 'user'
    // }
    //{
    //    text: 'Manuale',
    //    path: '/manuale',
    //    icon: 'help'
    //}
];